import { debounce } from "lodash";

const uuid=() => ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, (c) =>
    (c^(crypto.getRandomValues(new Uint8Array(1))[0]&(15>>(c/4)))).toString(16)
);

export default class RequestInspector {
    constructor(app) {
        this.app=app;
        this.toastedErrors={
            400: {
                msg: "bad_request",
                options: {
                    type: "error",
                    icon: "fa-exclamation-triangle"
                }
            },
            404: {
                msg: "not_found",
                options: {
                    type: "error",
                    icon: "fa-exclamation-triangle"
                },
                details: (request) => {
                    const url=`${request.root}/${request.url}`;
                    const url_id=(url.match(/\/[\d]+/)||[""])[0].replace(/\//, "");
                    const type=
                        (/\/connector/.test(url))? "connector":
                            (/\/device/.test(url))? "device":
                                (/\/data/.test(url))? "data":
                                    (/\/alarm/.test(url))? "alarm":
                                        (/\/text_lists/.test(url))? "text_list":"";
                    const id=url_id? `: ${url_id}`:"";
                    const text=type? `${this.app.$tc(type, 1)}${id}`:'';
                    return text;
                }
            },
            429: {
                msg: "too_many_requests",
                options: {
                    type: "error",
                    icon: "fa-exclamation-triangle"
                }
            }
        };
        // REAL TIME UPDATE (mqtt resource sync)
        // if (this.app.$rt) {
        //     this.app.$rt.addListener("mqtt:message", ($e) => this.onMessage($e.detail));
        // }
    }

    onMessage ($e) {
        if (
            !$e||
            !$e.msg||
            $e.msg.level!=="info"||
            $e.msg.msg!=="resources_updated"||
            !$e.msg.origin||
            $e.msg.origin===this.id||
            !$e.msg.payload
        ) return;
        this.updateResources($e.msg.payload);
    }

    // The mqtt message arrives before the entry be proper stored into the database. The injected delay is an attempt to prevent (minimize) such error
    async updateResources (payload) {
        setTimeout(() => {
            try {
                let entry;
                if (/^(connector|device)$/.test(payload.type)) {
                    if (!payload?.id_list?.length) return;
                    if (payload.method=="DELETE") {
                        entry=(payload.id_list||[]).map((id) => {
                            return payload.type=="connector"? ({ connector_id: id }):({ device_id: id })
                        });
                        this.app.$store.dispatch("dashboard/removeResources", entry);
                    } else {
                        let id=payload.id_list[0];
                        entry={
                            connector_id: id
                        };
                        this.app.$store.dispatch("dashboard/fetchDevices", entry).then((ret) => {
                            if (!ret||!payload.deep) return;
                            this.app.$store.dispatch("dashboard/fetchResourcesFrom", { connectorId: id, resource: "data", forceUpdate: true });
                            this.app.$store.dispatch("dashboard/fetchResourcesFrom", { connectorId: id, resource: "alarm", forceUpdate: true });
                        });
                    }
                } else if (/^(data|alarm)$/.test(payload.type)) {
                    var type=/^(data)$/.test(payload.type)&&"data"||"alarm"
                    if (payload.method=="DELETE") {
                        if (!payload?.id_list?.length) return;
                        entry=(payload.id_list||[]).map((id) => ({ [`${type}_id`]: id }));
                        this.app.$store.dispatch("dashboard/removeResources", entry);
                    } else {
                        if (!payload.connector_id) return;
                        entry={
                            resource: type,
                            connectorId: payload.connector_id,
                            forceUpdate: true
                        };
                        if (payload?.id_list?.length) {
                            entry.ids=payload.id_list.join(",");
                        }
                        this.app.$store.dispatch("dashboard/fetchResourcesFrom", entry);
                        if (type=='data'&&payload.deep) {
                            this.app.$store.dispatch("dashboard/fetchResourcesFrom", { connectorId: payload.connector_id, resource: "alarm", forceUpdate: true });
                        }
                    }
                }
            } catch (error) { }
        }, 4000);
    }

    async publish (request) {
        try {
            if (!this.app||!this.app.$rt||!request||!request.url||!request.method) return;
            const url=`${request.root}/${request.url}`;
            const url_id=(url.match(/\/[\d]+/)||[""])[0].replace(/\//, "");
            const payload=!request.body? {}:typeof request.body==='string'? JSON.parse(request.body):request.body;
            let method=request.method;
            let id_list, type, connector, deep=false;
            if (/\/connector/.test(url)) {
                switch (method) {
                    case "PATCH": {
                        id_list=(Array.isArray(payload||{})? payload:[payload||{}]).map(({ id }) => id);
                        if (!(id_list||[]).length&&this) return;
                        connector=(
                            this.app.$store.getters["dashboard/connectorList"]||[]
                        ).find(({ id }) => parseInt(id)===parseInt(id_list[0]));
                        if (!connector) return;
                        type="connector";
                        break;
                    }
                    case "POST": {
                        break;
                    }
                    case "DELETE": {
                        if (!url_id) return;
                        connector=(
                            this.app.$store.getters["dashboard/connectorList"]||[]
                        ).find(({ id }) => parseInt(id)===parseInt(url_id));
                        if (!connector) return;
                        id_list=[url_id];
                        type="connector";
                        break;
                    }
                }
            } else if (/\/device/.test(url)) {
                switch (method) {
                    case "PATCH": {
                        id_list=(Array.isArray(payload||{})? payload:[payload||{}]).map(({ id }) => id);
                        if (!(id_list||[]).length&&this) return;
                        let device=(
                            this.app.$store.getters["dashboard/deviceList"]||[]
                        ).find(({ id }) => parseInt(id)===parseInt(id_list[0]));
                        connector=device?.connector;
                        if (!connector&&device?.connector_id) {
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(device.connector_id));
                        }
                        if (!connector) return;
                        id_list=[connector.id];
                        type="connector";
                        break;
                    }
                    case "POST": {
                        if (/\/remove/.test(url)) {
                            if (!payload?.connector_id||!(payload?.resource_ids||[]).length) return;
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(payload?.connector_id));
                            if (!connector) return;
                            method="DELETE";
                            id_list=payload?.resource_ids;
                            type="device";
                        } else if (/\/import/.test(url)) {
                            var connector_id=payload.get&&payload.get("connector_id");
                            if (!connector_id) return;
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(connector_id));
                            if (!connector) return;
                            id_list=[connector.id];
                            type="connector";
                        } else {
                            if (payload?.connector_id) {
                                connector=(
                                    this.app.$store.getters["dashboard/connectorList"]||[]
                                ).find(({ id }) => parseInt(id)===parseInt(payload?.connector_id));
                            }
                            if (!connector) return;
                            id_list=[connector.id];
                            type="connector";
                            deep=(/\/duplicate/.test(url))
                        }
                        break;
                    }
                    case "DELETE": {
                        if (!url_id) return;
                        let device=(this.app.$store.getters["dashboard/deviceList"]||[]).find(({ id }) => parseInt(id)===parseInt(url_id));
                        connector=device?.connector;
                        if (!connector&&device?.connector_id) {
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(device.connector_id));
                        }
                        if (!connector) return;
                        id_list=[url_id];
                        type="device";
                        break;
                    }
                }
            } else if (/\/data/.test(url)) {
                switch (method) {
                    case "PATCH": {
                        const body=payload?.data||payload;
                        id_list=(Array.isArray(body||{})? body:[body||{}]).map(({ id }) => id);
                        if (!(id_list||[]).length&&this) return;
                        let data=(this.app.$store.getters["dashboard/dataList"]||[]).find(({ id }) => parseInt(id)===parseInt(id_list[0]));
                        connector=data?.device?.connector;
                        if (!connector&&data&&data.clp_id) {
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(data.clp_id));
                        }
                        if (!connector) return;
                        type="data";
                        break;
                    }
                    case "POST": {
                        if (/\/remove/.test(url)) {
                            if (!payload?.connector_id||!(payload?.resource_ids||[]).length) return;
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(payload?.connector_id));
                            if (!connector) return;
                            method="DELETE";
                            id_list=payload?.resource_ids;
                            type="data";
                        } else if (/\/import/.test(url)) {
                            var connector_id=payload.get&&payload.get("connector_id");
                            if (!connector_id) return;
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(connector_id));
                            if (!connector) return;
                            id_list=[];
                            type="data";
                        } else {
                            if (!payload?.device_id) return;
                            let device=(this.app.$store.getters["dashboard/deviceList"]||[]).find(({ id }) => parseInt(id)==parseInt(payload.device_id));
                            connector=device?.connector;
                            if (!connector&&device?.connector_id) {
                                connector=(
                                    this.app.$store.getters["dashboard/connectorList"]||[]
                                ).find(({ id }) => parseInt(id)===parseInt(device.connector_id));
                            }
                            if (!connector) return;
                            id_list=[];
                            type="data";
                            deep=(/\/duplicate/.test(url))
                        }
                        break;
                    }
                    case "DELETE": {
                        if (!url_id) return;
                        let data=(this.app.$store.getters["dashboard/dataList"]||[]).find(({ id }) => parseInt(id)===parseInt(url_id));
                        connector=data?.device?.connector;
                        if (!connector&&data&&data.clp_id) {
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(data.clp_id));
                        }
                        if (!connector) return;
                        id_list=[url_id];
                        type="data";
                        break;
                    }
                }
            } else if (/\/alarm/.test(url)) {
                switch (method) {
                    case "PATCH": {
                        const body=payload?.data||payload;
                        id_list=(Array.isArray(body||{})? body:[body||{}]).map(({ id }) => id);
                        if (!(id_list||[]).length&&this) return;
                        let alarm=this.app.$store.getters["dashboard/alarmList"].find(({ id }) => parseInt(id)===parseInt(id_list[0]))
                        if (alarm.connector_id) {
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(alarm.connector_id));
                        }
                        if (!connector) return;
                        type="alarm";
                        break;
                    }
                    case "POST": {
                        if (/\/remove/.test(url)) {
                            if (!payload?.connector_id||!(payload?.resource_ids||[]).length) return;
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(payload?.connector_id));
                            if (!connector) return;
                            method="DELETE";
                            id_list=payload?.resource_ids;
                            type="alarm";
                        } else if (/\/import/.test(url)) {
                            var connector_id=payload.get&&payload.get("connector_id");
                            if (!connector_id) return;
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(connector_id));
                            if (!connector) return;
                            id_list=[];
                            type="alarm";
                        } else {
                            if (!payload?.data_id) return;
                            let data=(this.app.$store.getters["dashboard/dataList"]||[]).find(({ id }) => parseInt(id)==parseInt(payload.data_id));
                            connector=data?.device?.connector;
                            if (!connector&&data&&data.clp_id) {
                                connector=(
                                    this.app.$store.getters["dashboard/connectorList"]||[]
                                ).find(({ id }) => parseInt(id)===parseInt(data.clp_id));
                            }
                            if (!connector) return;
                            id_list=[];
                            type="alarm";
                        }
                        break;
                    }
                    case "DELETE": {
                        if (!url_id) return;
                        let data=(this.app.$store.getters["dashboard/dataList"]||[]).find(({ id }) => parseInt(id)===parseInt(url_id));
                        connector=data?.device?.connector;
                        if (!connector&&data&&data.clp_id) {
                            connector=(
                                this.app.$store.getters["dashboard/connectorList"]||[]
                            ).find(({ id }) => parseInt(id)===parseInt(data.clp_id));
                        }
                        if (!connector) return;
                        id_list=[url_id];
                        type="data";
                        break;
                    }
                }
            }
            if (!connector||!type) return;
            this.app.$rt.mqtt.publish(`${connector.mqtt_topic_prefix}/connector_events`, {
                origin: this.id,
                level: "info",
                msg: "resources_updated",
                payload: {
                    type: type,
                    method: method,
                    id_list: id_list,
                    connector_id: connector.id,
                    deep: deep
                }
            });
        } catch (error) { }
    }

    get id () {
        return this._id||(this._id=uuid()); // it is used to avoid receiving msg sent by me;
    }

    async notifyReqError (request, response) {
        this._notifyReqError=
            this._notifyReqError||
            debounce((code, msg, details) => {
                if ((!this.app.$store.getters["user/isLogged"])||(document.querySelector(".swal-overlay--show-modal"))) return;
                msg=msg? msg:`${this.app.$t(this.toastedErrors[code].msg)}`;
                msg=`${msg}<br/>${details}`;
                this.app.$utils.notifyUser(this.app, msg, this.toastedErrors[code].options);
            }, 3000);
        const details=this.toastedErrors[response.status]?.details? this.toastedErrors[response.status]?.details(request):'';
        let msg=typeof response.body=="string"? response.body:typeof response.body=='object'? response?.body?.detail:"";
        this._notifyReqError(response.status, msg, details);
    }

    onBefore (request) {
        // TODO: before request
    }

    onAfter (request, response) {
        // TODO: after request 
        if (this.toastedErrors[response.status]) {
            this.notifyReqError(request, response);
            // } else if (
            //     response.status - (response.status % 10) === 200 &&
            //     { DELETE: true, POST: true, PATCH: true }[request.method]
            // ) {
            //     this.publish(request);
        }
    }
}
